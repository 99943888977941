import React, { useEffect, useState, Suspense } from "react";

import { Routes, Route, useLocation, Navigate } from "react-router-dom";
 import moment from "moment";
import 'moment/locale/ru';
import 'moment/locale/uz';
import ProtectedRoutes from "./ProtectedRoutes/ProtectedRoutes";
import { useSwitchUpDownMutation } from "./services/AllApi";
import { useSelector } from "react-redux";
import Loader from "@components/Loader/Loader";
import { useTranslation } from "react-i18next";
import TechnicalError from "@components/ui/TechnicalError";

// Lazy load components
const Navbar = React.lazy(() => import("@components/Navbar/Navbar"));
const Footer = React.lazy(() => import("@components/Footer/Footer"));
const Home = React.lazy(() => import("@pages/Home"));
const Profile = React.lazy(() => import("@pages/profile"));
const ShopTicket = React.lazy(() => import("@components/ShopTicket/ShopTicket"));
const BasicModal = React.lazy(() => import("@components/Modal/Modal"));
const UzCardPayment = React.lazy(() => import("@pages/Uz-card-payment"));
const AboutUs = React.lazy(() => import("@pages/aboutUs"));
const Sale = React.lazy(() => import("@pages/Sale"));
const Faq = React.lazy(() => import("@pages/faq"));
const GetTicket = React.lazy(() => import("@components/ShopTicket/GetTicket"));


function App() {
  const { t } = useTranslation();
  const lang = useSelector((state) => state.AllSlice.lang);
  const loggedIn = useSelector((state) => state.LoginSlice.loggedIn);
  const [isMaintenanceMode, setIsMaintenanceMode] = useState(false);
  const [switchUpDown, { data: switchUpDownData, isSuccess: switchUpDownSuc }] = useSwitchUpDownMutation();
  const location = useLocation();
  const hideNavbarAndFooter = location.pathname.startsWith('/payment');

  moment.locale(lang);


  useEffect(() => {
    if (window.location.href === 'https://2aqtzt5.257.cz/' || window.location.href === 'https://2aqtzt5.257.cz') {
      window.location.href = 'https://mysafar.uz/';
    }
  }, []);

  useEffect(() => {
    switchUpDown();
  }, []);

  useEffect(() => {
    if (switchUpDownSuc) {
      setIsMaintenanceMode(switchUpDownData.value === '0');
    }
  }, [switchUpDownSuc, switchUpDownData]);

  if (isMaintenanceMode) {
    return <TechnicalError/>
  }


  return (
    <Suspense fallback={<Loader />}>
      {!hideNavbarAndFooter || !loggedIn ? <Navbar /> : ''}
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/search/:id' element={<Home />} />
        <Route path="/aboutUs" element={<AboutUs />} />
        <Route path="/sale" element={<Sale />} />
        <Route path="/faq" element={<Faq />} />
        <Route element={<ProtectedRoutes />}>
          <Route path="/profile" element={<Profile />} />
          <Route path='/order/:id' element={<ShopTicket />} />
          <Route path="/payment/:id/:id" element={<UzCardPayment />} />
          <Route path="/getTicket/:id" element={<GetTicket />} />
        </Route>
        <Route path='*' element={<Navigate to="/" />} />
      </Routes>
      <BasicModal />
      {!hideNavbarAndFooter && <Footer />}
    </Suspense>
  );
}

export default App;
