// src/store.js
import { configureStore } from "@reduxjs/toolkit";
import { AllApi } from "@services/AllApi";
import { LoginApi } from "@services/LoginApi";
import { ProfileApi } from "@services/ProfileApi";
import { RegisterApi } from "@services/RegisterApi";
import { getMyCityApi } from "@services/MyCityApi";
import AllSlice from '@Slice/AllSlice'
import LoginSlice from '@Slice/LoginSlice'
import HomeSlice from '@Slice/HomeSlice'
import { BookingApi } from "@services/Booking";

export const Store = configureStore({
  reducer: {
    AllSlice: AllSlice,
    LoginSlice: LoginSlice,
    HomeSlice: HomeSlice,
    [AllApi.reducerPath]: AllApi.reducer,
    [LoginApi.reducerPath]: LoginApi.reducer,
    [ProfileApi.reducerPath]: ProfileApi.reducer,
    [BookingApi.reducerPath]: BookingApi.reducer,
    [RegisterApi.reducerPath]: RegisterApi.reducer,
    [getMyCityApi.reducerPath]: getMyCityApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(AllApi.middleware)
      .concat(getMyCityApi.middleware)
      .concat(LoginApi.middleware)
      .concat(ProfileApi.middleware)
      .concat(RegisterApi.middleware)
      .concat(BookingApi.middleware),
});
