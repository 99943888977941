import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const AllApi = createApi({
  reducerPath: "AllApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api.mysafar.ru",
  }),

  endpoints: (builder) => ({
    searchAirports: builder.mutation({
      query: (airport) => ({
        url: "/avia/airports",
        method: "POST",
        body: airport,
      }),
    }),

    searchAirportsTo: builder.mutation({
      query: (airport) => ({
        url: "/avia/airports",
        method: "POST",
        body: airport,
      }),
    }),

    getRecommendation: builder.mutation({
      query: (recommendationBody) => ({
        url: "/avia/get-recommendations",
        method: "POST",
        body: recommendationBody,
        headers: {
          Authorization: localStorage.getItem("access") && `Bearer ${localStorage.getItem("access")}`
        }
      }),
    }),


    flightInfo: builder.mutation({
      query: (flightBody) => ({
        url: "/avia/get-flight-info",
        method: "POST",
        body: flightBody,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`
        }
      }),
    }),

    popularCites: builder.mutation({
      query: () => ({
        url: "/main/popcites",
        method: "POST",
      }),
    }),





    getTicketPrice: builder.mutation({
      query: (ticketPriceBody) => ({
        url: "/avia/one-month-ticket-price",
        method: "POST",
        body: ticketPriceBody,
      }),
    }),

    switchUpDown: builder.mutation({
      query: () => ({
        url: "admina/get-avia-service-switcher",
        method: "GET",
      }),
    }),

    

  }),
});

export const { useSearchAirportsMutation, useSearchAirportsToMutation, useGetRecommendationMutation, useFlightInfoMutation, usePopularCitesMutation, useGetTicketPriceMutation, useSwitchUpDownMutation } = AllApi;
