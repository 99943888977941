import React from 'react';
import './loader.css';
import PlaneImg from '../../assets/airlines/planeImg.svg'
import { Helmet } from 'react-helmet'

export default function Loader() {
  return (
    <div className='loader-container'>
      <div className="loader">
        <div className='plane-container'>
          <div className="plane">
            {/* Optimized Image or SVG */}
              <img
                src={PlaneImg}
                className="plane-img"
                alt="Plane animation"
                width="250"
                height="250"
                rel='preload'
                typeof='svg'
                loading="lazy"  // Since this is crucial for the loader, avoid lazy loading
              />
          </div>
          <div className="earth-wrapper">
            {/* Earth Animation using CSS */}
            <div className="earth"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
